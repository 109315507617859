<template>
    <b-overlay :show="loading">
        <b-row class="mb-2">
            <!-- Header -->
            <b-col cols="12" class="mb-2">
                <b-button variant="primary" v-if="allowCreate()" @click.prevent="add">
                    <feather-icon icon="PlusIcon" class="mr-50" />Penyusutan
                </b-button>
            </b-col>
            <b-col sm="12" md="6">
                <b-form-group class="mb-0">
                    <label class="d-inline-block text-sm-left mr-50">Per page</label>
                    <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
                </b-form-group>
            </b-col>
            <b-col sm="12" md="6">
                <b-form-group :label="$t('Filter')" label-cols-sm="3" label-align-sm="right" label-size="sm"
                    label-for="filterInput" class="mb-0">
                    <b-input-group size="sm">
                        <b-form-input id="filterInput" v-model="filter" type="search"  />
                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">{{$t('Clear')}}</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <!-- / -->

        </b-row>
        <!-- Body -->
        <b-card>
            <b-row>
                <b-col cols="12">
                    <b-table :filter="filter" :per-page="perPage" :current-page="currentPage" responsive
                        :fields="fields" :items="penyusutans">
                        <template #cell(order)="{ index }">
                            {{ ++index }}
                        </template>
                        <template #cell(biaya)="{ item }">
                            Rp {{ item.biaya ? formatRupiah(item.biaya) : item.biaya }}
                        </template>
                        <template #cell(tanggal)="{ item }">
                            {{ item.tanggal ? humanDate(item.tanggal) : item.tanggal }}
                        </template>
                        <!-- <template #cell(status)="{item}">
                            <div class="d-flex justify-content-center">
                                <b-badge :variant="item.status_variant">{{ item.status_ket ? capitalize(item.status_ket) : 'Menunggu persetujuan Owner' }}</b-badge>
                            </div>
                        </template> -->
                        <template #cell(actions)="{ item }">
                            <div class="flex align-items-center justify-center">
                                <b-button size="sm" @click="$router.push(`/asset-penyusutan/detail/${item.id}`)"
                                    class="mr-1" variant="outline-info">
                                    <feather-icon icon="EyeIcon" />
                                </b-button>
                                <b-button v-if="allowUpdate() && item.id_akun == null" size="sm"
                                    @click.prevent="edit(item)" class="mr-1" variant="outline-info">
                                    <feather-icon icon="EditIcon" />
                                </b-button>
                                <b-button v-if="(allowDelete() && item.id_akun == null)" size="sm" @click="remove(item)"
                                    class="mr-1" variant="outline-danger">
                                    <feather-icon icon="TrashIcon" />
                                </b-button>
                            </div>
                        </template>
                    </b-table>
                </b-col>
                <b-col cols="12">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center"
                        size="sm" class="my-0" />
                </b-col>
            </b-row>
        </b-card>
        <!-- / -->
        <!-- Modal create -->
        <b-modal id="form-penyusutan" centered size="lg" title="Form Penyusutan Aset">
            <validation-observer ref="form-penyusutan">
                <!-- <b-form-group label="Supplier">
                    <validation-provider
                        #default="{ errors }"
                        name="supplier"
                        rules="required"
                    >
                        <v-select v-model="form.id_supplier" :options="suppliers" :reduce="option => option.value" label="text"></v-select>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group> -->
                <b-form-group label="Tahun">
                    <validation-provider #default="{ errors }" name="tahun" rules="required">
                        <b-form-input v-model="form2.tahun"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Bulan">
                    <validation-provider #default="{ errors }" name="bulan" rules="required">
                        <b-form-select v-model="form2.bulan" :options="optbulan"></b-form-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Tanggal Penyusutan">
                    <validation-provider #default="{ errors }" name="tanggal" rules="required">
                        <b-form-input type="date" v-model="form2.tanggal"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Biaya">
                    <validation-provider #default="{ errors }" name="biaya" rules="required">
                        <b-input-group>
                            <b-form-input v-model="form.biaya" @keyup="doFormatRupiah"></b-form-input>
                            <b-input-group-append>
                                <b-button variant="outline-primary" @click.prevent="submitcekbiaya">
                                    Cek Biaya
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </validation-observer>
            <template #modal-footer>
                <b-button @click.prevent="submit" variant="primary">Simpan</b-button>
            </template>
        </b-modal>
        <!-- / -->
    </b-overlay>
</template>
<script>
import { BOverlay, BFormTextarea, BRow, BBadge, BCol, BCard, BPagination, BTable, BFormGroup, BInputGroup, BInputGroupAppend, BButton, BFormInput, BFormSelect } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        BOverlay, BFormTextarea,
        BRow, BCol, BBadge, BFormGroup, BCard, BPagination, BTable, BInputGroup, BInputGroupAppend, BButton, BFormInput, BFormSelect,
        vSelect
    },
    data: () => ({
        required,
        loading: false,
        perPage: 10,
        totalRows: 0,
        currentPage: 1,
        pageOptions: [10, 30, 50],
        filter: null,
        fields: [
            { key: 'order', label: 'No' },
            { key: 'tahun', label: 'Tahun' },
            { key: 'bulan', label: 'bulan' },
            { key: 'tanggal', label: 'Tanggal' },
            { key: 'biaya', label: 'Biaya' },
            { key: 'akun.nama', label: 'akun' },
            { key: 'actions', label: '#' }
        ],
        penyusutans: [],
        form: {
            biaya: null,
        },
        form2: {
            biaya: null,
            tanggal: null,
            tahun: null,
            bulan: null
        },
        optbulan: [
            { value: 1, text: "Januari" },
            { value: 2, text: "Februari" },
            { value: 3, text: "Maret" },
            { value: 4, text: "April" },
            { value: 5, text: "Mei" },
            { value: 6, text: "Juni" },
            { value: 7, text: "Juli" },
            { value: 8, text: "Agustus" },
            { value: 9, text: "September" },
            { value: 10, text: "Oktober" },
            { value: 11, text: "November" },
            { value: 12, text: "Desember" },
        ],
        // form:{
        //     biaya: null
        // },
    }),
    watch: {
        "form2.tanggal"(d){
            const value = d
            const hasil = `${value.getMonth()}` < 10 ? `${value.getMonth() + 1}` : value.getMonth() + 1
            const hasil2 = `${value.getFullYear()}`
            this.form.bulan = hasil
            this.form.tahun = hasil2
        }
    },
    methods: {
        doFormatRupiah() {
            this.form.biaya = this.formatRupiah(this.form.biaya)
        },
        async submitcekbiaya() {
            const params = {
                tanggal: this.form2.tanggal
            };
            this.form = await this.$store.dispatch('asset/getCekBiayaPenyusutan', params)
            if(this.form.biaya) {
                this.form.biaya = this.formatRupiah(this.form.biaya)
            }
        },
        submit() {
            this.$refs['form-penyusutan'].validate().then(async success => {
                if (success) {
                    this.form2.biaya = this.unFormatRupiah(this.form.biaya)
                    const payload = this.form2
                    if (this.id) {
                        payload.id = this.id
                    }
                    try {
                        this.loading = true
                        const penyusutan = await this.$store.dispatch('asset/savePenyusutan', [payload])
                        await this.getPenyusutan()
                        this.loading = false
                        this.id = null
                        if (this.id) {
                            this.$bvModal.hide('form-penyusutan')
                        }
                        else {
                            this.$router.push(`/asset-penyusutan/detail/${penyusutan.id}`)
                        }
                    }
                    catch (e) {
                        this.loading = false
                        this.displayError(e)
                        return false
                    }
                }
            })
        },
        edit(item) {
            this.id = item.id
            this.form.gudang_id = item.gudang_id
            this.form.id_supplier = item.id_supplier
            this.form.tanggal = item.tanggal
            this.form.keterangan = item.keterangan
            this.$bvModal.show('form-penyusutan')
        },
        remove(item) {
            this.$swal({
                title: 'Anda yakin?',
                text: `Data penyusutan ini akan dihapus`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                cancelButtonText: 'Batal',
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-1',
                }
            })
                .then(async res => {
                    if (res.value) {
                        const payload = {
                            fungsi: 2,
                            id: item.id,
                            asset_id: item.asset_id,
                            bulan: item.bulan,
                            tahun: item.tahun
                        }

                        try {
                            this.loading = true
                            await this.$store.dispatch('asset/savePenyusutan', [payload])
                            this.loading = false
                            this.displaySuccess({
                                message: 'Data penyusutan berhasil dihapus'
                            })
                            this.getPenyusutan()
                        }
                        catch (e) {
                            this.loading = false
                            this.displayError(e)
                            return false
                        }
                    }
                })
        },
        resetForm() {
            this.id = null
            this.form.id = null
            this.form.gudang_id = null
            this.form.id_supplier = null
            this.form.tanggal = this.getCurrentDate()
            this.form.keterangan = null
        },
        add() {
            // this.resetForm()
            this.$bvModal.show('form-penyusutan')
        },
        getVariant(item) {
            let result = 'danger'

            switch (item.status) {
                case 1:
                    result = 'info'
                    break
                case 2:
                    result = 'dark'
                    break
                case 3:
                    result = 'warning'
                    break
                case 4:
                    result = 'dark'
                    break
                case 5:
                    result = 'success'
                    break
                default:
                    result = 'danger'
            }

            return result
        },
        async getPenyusutan() {
            this.loading = true
            // const params = {order: 'desc'}
            // if(this.myGudang) {
            //     params.gudang_id = this.myGudang.id
            // }
            const penyusutans = await this.$store.dispatch('asset/getPenyusutan')
            // const pendingPenyusutan = penyusutans.filter(item => item.status == 0) // for owner
            // const approveOwnerPenyusutan = penyusutans.filter(item => item.status == 1) // for finance
            // const approveFinancePenyusutan = penyusutans.filter(item => item.status == 3) // for admin aset
            // const otherPenyusutan = penyusutans.filter(item => [2, 4, 5].includes(item.status))
            // this.penyusutans = [...pendingPenyusutan, ...approveOwnerPenyusutan, ...approveFinancePenyusutan, ...otherPenyusutan]
            // this.penyusutans.map(item => item.status_variant = this.getVariant(item))
            // if(this.isAdminAsset) {
            //     this.penyusutans = [...approveFinancePenyusutan, ...pendingPenyusutan, ...approveOwnerPenyusutan, ...otherPenyusutan]
            // }
            // else if(this.isFinance) {
            //     this.penyusutans = [...approveOwnerPenyusutan, ...approveFinancePenyusutan, ...pendingPenyusutan, ...otherPenyusutan]
            // }
            this.penyusutans = penyusutans
            this.totalRows = this.penyusutans.length
            this.loading = false
        },
        detail(item) {
            this.$router.push(`/penyusutan/detail/${item.id}`)
        },
        async getSupplier() {
            const params = this.isOwner || isCEO ? { jenis: 1 } : { jenis: 2 }
            const suppliers = await this.$store.dispatch('supplier/getData', params)
            this.suppliers = suppliers.map(item => ({ value: item.id, text: item.instansi }))
        },
    },
    created() {
        this.form2.tanggal = this.getCurrentDate()
        this.form2.bulan = this.getBulan()
        this.form2.tahun = this.getTahun()
        this.getPenyusutan()
        this.doFormatRupiah()
        // this.getSupplier()
    }
}
</script>