var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_vm.allowCreate() ? _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.add($event);
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "PlusIcon"
    }
  }), _vm._v("Penyusutan ")], 1) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function ($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Filter'),
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search"
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function ($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('Clear')))])], 1)], 1)], 1)], 1)], 1), _c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "filter": _vm.filter,
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "responsive": "",
      "fields": _vm.fields,
      "items": _vm.penyusutans
    },
    scopedSlots: _vm._u([{
      key: "cell(order)",
      fn: function (_ref) {
        var index = _ref.index;
        return [_vm._v(" " + _vm._s(++index) + " ")];
      }
    }, {
      key: "cell(biaya)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" Rp " + _vm._s(item.biaya ? _vm.formatRupiah(item.biaya) : item.biaya) + " ")];
      }
    }, {
      key: "cell(tanggal)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.tanggal ? _vm.humanDate(item.tanggal) : item.tanggal) + " ")];
      }
    }, {
      key: "cell(actions)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "flex align-items-center justify-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-info"
          },
          on: {
            "click": function ($event) {
              return _vm.$router.push(("/asset-penyusutan/detail/" + (item.id)));
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EyeIcon"
          }
        })], 1), _vm.allowUpdate() && item.id_akun == null ? _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-info"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.edit(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1) : _vm._e(), _vm.allowDelete() && item.id_akun == null ? _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrashIcon"
          }
        })], 1) : _vm._e()], 1)];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "form-penyusutan",
      "centered": "",
      "size": "lg",
      "title": "Form Penyusutan Aset"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.submit($event);
            }
          }
        }, [_vm._v("Simpan")])];
      },
      proxy: true
    }])
  }, [_c('validation-observer', {
    ref: "form-penyusutan"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tahun"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "tahun",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref5) {
        var errors = _ref5.errors;
        return [_c('b-form-input', {
          model: {
            value: _vm.form2.tahun,
            callback: function ($$v) {
              _vm.$set(_vm.form2, "tahun", $$v);
            },
            expression: "form2.tahun"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Bulan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "bulan",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref6) {
        var errors = _ref6.errors;
        return [_c('b-form-select', {
          attrs: {
            "options": _vm.optbulan
          },
          model: {
            value: _vm.form2.bulan,
            callback: function ($$v) {
              _vm.$set(_vm.form2, "bulan", $$v);
            },
            expression: "form2.bulan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Tanggal Penyusutan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "tanggal",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref7) {
        var errors = _ref7.errors;
        return [_c('b-form-input', {
          attrs: {
            "type": "date"
          },
          model: {
            value: _vm.form2.tanggal,
            callback: function ($$v) {
              _vm.$set(_vm.form2, "tanggal", $$v);
            },
            expression: "form2.tanggal"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Biaya"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "biaya",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref8) {
        var errors = _ref8.errors;
        return [_c('b-input-group', [_c('b-form-input', {
          on: {
            "keyup": _vm.doFormatRupiah
          },
          model: {
            value: _vm.form.biaya,
            callback: function ($$v) {
              _vm.$set(_vm.form, "biaya", $$v);
            },
            expression: "form.biaya"
          }
        }), _c('b-input-group-append', [_c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.submitcekbiaya($event);
            }
          }
        }, [_vm._v(" Cek Biaya ")])], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }